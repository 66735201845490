import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

type On = {
  readonly type: 'On';
};

type Off = {
  readonly type: 'Off';
};

export type AppointmentPaymentsOption = On | Off;

const ON = {
  type: 'On',
} as const;

const OFF = {
  type: 'Off',
} as const;

const JSON: t.Type<AppointmentPaymentsOption, JSONable> = t.union(
  [
    t.type(
      {
        type: t.literal('On'),
      },
      'On',
    ),
    t.type(
      {
        type: t.literal('Off'),
      },
      'Off',
    ),
  ],
  'AppointmentPaymentsOption',
);

export const AppointmentPaymentsOption = {
  ON,
  OFF,
  DEFAULT: ON,
  JSON,
};
