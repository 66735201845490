import { ClientId } from '../../clients/client-id';
import { HasOptionalFirstLastName } from '../../common';
import { Coupon } from '../../coupons';
import { PageId } from '../../pages/page-id';
import { UserId } from '../../users/user-id';
import { WorkerId } from '../../workers/workerId';
import { AppointmentId } from '../appointment-id';
import { AppointmentStatus } from '../appointment-status';
import { CalendarId } from '../calendar-id';
import { AppointmentBoostStatus } from './appointmentBoostStatus';
import { BookedService } from './bookedService';
import { Type } from './type';
import {
  HasId,
  JSONable,
  Nullean,
  Option,
  optionull,
  PhoneNumber,
  PhoneNumberParser,
  ProfileImage,
} from '@mero/shared-sdk';
import * as t from 'io-ts';
import * as tt from 'io-ts-types';

/**
 * Appointment calendar entry payload type
 */
type Payload = {
  readonly status: AppointmentStatus;
  readonly page: HasId<PageId>;
  readonly worker: HasId<WorkerId> &
    HasOptionalFirstLastName & {
      readonly profilePhoto: ProfileImage;
    };
  readonly bookedServices: BookedService[];
  /**
   * @deprecated use client field (phone and profile photo will be added there)
   */
  readonly user?: {
    readonly _id: UserId;
    readonly phone: PhoneNumber;
    readonly profile: HasOptionalFirstLastName & {
      readonly photo?: ProfileImage;
    };
  };
  readonly clientBoostStatus?: AppointmentBoostStatus;
  readonly hideBoostClientDetails?: Nullean;
  readonly client?: {
    readonly _id: ClientId;
    readonly isBlocked: boolean;
    readonly isWarned: boolean;
    readonly isFavourite: boolean;
  } & HasOptionalFirstLastName;
  readonly note?: string;
  readonly coupons?: Coupon[];
  readonly cancelInfo?: {
    readonly by: 'pro' | 'client';
    readonly at?: Date;
    readonly reason?: string;
  };
  readonly hasFinishedCheckoutTransactions?: boolean;
};

/**
 * Payload type for Appointment calendar entry type
 */
const Payload: t.Type<Payload, JSONable> = t.intersection(
  [
    t.type(
      {
        status: AppointmentStatus,
        page: HasId.json(PageId),
        worker: t.intersection(
          [
            HasId.json(WorkerId.JSON),
            HasOptionalFirstLastName,
            t.type({
              profilePhoto: ProfileImage,
            }),
          ],
          'Worker',
        ),
        bookedServices: t.array(BookedService.JSON),
      },
      '!',
    ),
    t.partial({
      user: optionull(
        t.type(
          {
            _id: UserId,
            phone: PhoneNumberParser,
            profile: t.intersection(
              [
                HasOptionalFirstLastName,
                t.partial(
                  {
                    photo: optionull(ProfileImage),
                  },
                  '?',
                ),
              ],
              'UserProfile',
            ),
          },
          '!',
        ),
      ),
      clientBoostStatus: AppointmentBoostStatus.JSON,
      hideBoostClientDetails: Nullean,
      client: optionull(
        t.intersection(
          [
            t.type(
              {
                _id: ClientId,
                isBlocked: t.boolean,
                isWarned: t.boolean,
                isFavourite: t.boolean,
              },
              '!',
            ),
            HasOptionalFirstLastName,
          ],
          'Client',
        ),
      ),
      note: optionull(t.string),
      coupons: Option.json(t.array(Coupon.JSON)),
      cancelInfo: t.intersection([
        t.type({
          by: t.union([t.literal('pro'), t.literal('client')]),
        }),
        t.partial({
          at: tt.DateFromISOString,
          reason: t.string,
        }),
      ]),
      hasFinishedCheckoutTransactions: optionull(t.boolean),
    }),
  ],
  'CalendarEntry.Appointment.Payload',
);

export type Appointment = {
  readonly type: Type.Appointment;
  readonly _id: AppointmentId;
  readonly calendarId: CalendarId;
  readonly start: Date;
  readonly end: Date;
  readonly occurrenceIndex: number;
  readonly payload: Payload;
};

const JSON: t.Type<Appointment, JSONable> = t.type(
  {
    type: Type.Appointment.JSON,
    _id: AppointmentId,
    calendarId: CalendarId,
    start: tt.DateFromISOString,
    end: tt.DateFromISOString,
    occurrenceIndex: t.number,
    payload: Payload,
  },
  'CalendarEntry.Appointment',
);

export const Appointment = {
  JSON,
};
