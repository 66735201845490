import { Daily } from './daily';
import { Manual } from './manual';
import { Monthly } from './monthly';
import { Weekly } from './weekly';
import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export * from './manual';
export * from './daily';
export * from './weekly';
export * from './manual';

export type Any = Manual | Daily | Weekly | Monthly;

export const JSON: t.Type<Any, JSONable> = t.union([Manual.JSON, Daily.JSON, Weekly.JSON, Monthly.JSON], 'Any');
