import { JSONable } from '../common';
import * as t from 'io-ts';

export type UserFlags = {
  /**
   * User can create appointments in marketplace
   */
  readonly canCreateAppointmentInMp: boolean;
};

const DEFAULT = {
  canCreateAppointmentInMp: true,
};

const JSON: t.Type<UserFlags, JSONable> = t.type(
  {
    canCreateAppointmentInMp: t.boolean,
  },
  'UserFlags',
);

export const UserFlags = {
  JSON,
  DEFAULT,
};
