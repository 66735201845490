import { Option, None, option } from './option';

/**
 * Returns shuffled version of an array
 */
const shuffled = <T>(arr: T[]): T[] => {
  return arr
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);
};

/**
 * Returns first element of the array or None if the array is empty or the last element is null
 */
const first = <T>(arr: T[]): Option<T> => {
  if (arr.length > 0) {
    return option(arr[0]);
  }

  return None;
};

/**
 * Returns first element of the array or None if the array is empty or the last element is null
 */
const last = <T>(arr: T[]): Option<T> => {
  if (arr.length > 0) {
    return option(arr[arr.length - 1]);
  }

  return None;
};

export const Arrays = {
  shuffled,
  first,
  last,
};
